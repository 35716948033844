<template>
  <b-modal
    modal-class="component-modal-dxitem"
    id="DxItemModal"
    ref="DxItemModal"
    size="lg"
    title="รายการวินิจฉัย"
    hide-footer
    no-close-on-backdrop
    @close="close"
    @hide="close"
    @hidden="setDefaultValue"
  >
    <b-form @submit.prevent="saveDxItem">
      <b-form-group label-cols="3" label="รหัส:" label-align="right">
        <b-form-input v-model="dx.code" class> </b-form-input>
      </b-form-group>
      <b-form-group label-cols="3" label="ชื่อรายการ:" label-align="right">
        <b-form-input
          v-model="dx.nameEn"
          class="required"
          :class="{ 'is-invalid': $v.dx.nameEn.$error }"
        >
        </b-form-input>
      </b-form-group>

      <b-form-row>
        <div class="col-3 text-right align-self-center">ประเภท:</div>
        <b-form-select
          v-model="dx.cateId"
          class="col mx-1 required"
          :options="getTreatDiagCateList"
          value-field="id"
          text-field="nameTh"
          :class="{ 'is-invalid': $v.dx.cateId.$error }"
        >
          <template v-slot:first>
            <b-form-select-option :value="null" disabled
              >-- เลือกประเภท --</b-form-select-option
            >
          </template>
        </b-form-select>
        <b-button
          class="col-1 mx-1"
          block
          variant="secondary"
          @click="openDxCateItem()"
        >
          <i class="fas fa-edit"></i>
        </b-button>
        <!-- <div class="col-1"></div> -->

        <!-- <b-button
          class="col-2 mx-1"
          variant="outline-info"
          :pressed="dx.favorite"
          @click="dx.favorite = !dx.favorite"
        >
          <i class="far fa-star">ใช้ประจำ</i>
        </b-button> -->
      </b-form-row>
      <hr />
      <b-row class="d-flex flex-row-reverse">
        <div>
          <b-button class="ml-2 mr-3" type="submit" variant="primary">{{
            dx.id ? "แก้ไขรายการ" : "สร้างรายการ"
          }}</b-button>
        </div>
        <div>
          <b-button variant="danger" v-if="dx.id" @click="deleteDxItem(dx)"
            >ลบรายการ</b-button
          >
        </div>
      </b-row>
    </b-form>
    <Dialog ref="Dialog"></Dialog>
    <Loading v-if="isLoading"></Loading>
    <CateManage
      ref="DxCateItem"
      title="ประเภทวินิจฉัย"
      :categoryList="getTreatDiagCateList"
      :createCategoryFn="createDiagnosisCate"
      :deleteCategoryFn="deleteDiagnosisCate"
      :updateCategoryFn="updateDiagnosisCate"
      :fetchCategoryFn="fetchTreatDiagCateList"
    />
  </b-modal>
</template>

<script>
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";
import CateManage from "@/components/modal/CateManage";
import { mapActions, mapGetters } from "vuex";
import "@/theme/modal/AptItem.scss";
import {
  required,
  requiredIf,
  numeric,
  minLength,
  between,
  sameAs,
  not,
  helpers,
  email,
} from "vuelidate/lib/validators";

export default {
  name: "DxItemModal",
  components: {
    Dialog,
    Loading,
    CateManage,
  },
  data() {
    return {
      isLoading: false,
      dx: {
        id: null,
        code: "",
        nameTh: "",
        nameEn: "",
        cateId: null,
        favorite: false,
      },
    };
  },
  validations: {
    dx: {
      nameEn: { required },
      cateId: { required },
    },
  },
  computed: {
    ...mapGetters({
      getTreatDiagCateList: "moduleAppointment/getTreatDiagCateList",
    }),
    nameEn() {
      return this.dx.nameEn;
    },
  },
  watch: {
    nameEn(val) {
      this.dx.nameTh = val;
    },
  },
  methods: {
    ...mapActions({
      createDiagnosisCate: "moduleAppointment/createDiagnosisCate",
      deleteDiagnosisCate: "moduleAppointment/deleteDiagnosisCate",
      updateDiagnosisCate: "moduleAppointment/updateDiagnosisCate",
      fetchTreatDiagCateList: "moduleAppointment/fetchTreatDiagCateList",
    }),
    show(dxItem) {
      this.$bvModal.show("DxItemModal");
      if (dxItem) {
        this.dx = Object.assign({}, dxItem);
      }
    },
    saveDxItem() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      if (!this.dx.id) {
        delete this.dx.id;
        this.$store
          .dispatch("moduleAppointment/createTreatDiagList", {
            event: this.dx,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            if (res.status == 200) {
              this.$parent.showDialogToast(
                "success",
                `สร้างรายการ "${this.dx.nameTh}" สำเร็จ`
              );
            }
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("DxItemModal");
            this.setDefaultValue();
          });
      } else {
        delete this.dx.del;
        this.$store
          .dispatch("moduleAppointment/updateTreatDiagList", {
            event: this.dx,
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
          })
          .then((res) => {
            this.$parent.showDialogToast(
              "success",
              `แก้ไขรายการ "${this.dx.nameTh}" สำเร็จ`
            );
          })
          .finally(() => {
            this.isLoading = false;
            this.$bvModal.hide("DxItemModal");
          });
      }
    },
    deleteDxItem(eventData) {
      this.$refs.Dialog.showAlertConfirm(
        "ลบการวินิฉัยนี้ ?",
        "คุณต้องการลบการวินิฉัยนี้"
      ).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$store
            .dispatch("moduleAppointment/deleteTreatDiagList", {
              event: eventData,
              clinicUrl: this.$route.params.clinicUrl,
              branchUrl: this.$route.params.branchUrl,
            })
            .then((res) => {
              if (res.status == "200") {
                this.setDefaultValue();
                this.$parent.showDialogToast(
                  "success",
                  `ลบรายการ "${eventData.nameTh}" สำเร็จ`
                );
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$parent.showDialogToast(
                "warning",
                `ลบรายการ "${eventData.nameTh}" ไม่สำเร็จ`
              );
            })
            .finally(() => {
              this.$bvModal.hide("DxItemModal");
            });
        }
      });
    },
    close() {
      this.$v.$reset();
      this.setDefaultValue();
    },
    setDefaultValue() {
      this.dx = Object.assign(
        {},
        {
          id: null,
          code: "",
          name: "",
          cateId: null,
          favorite: false,
        }
      );
    },
    openDxCateItem() {
      this.$refs.DxCateItem.show();
    },
  },
};
</script>

<style>
.required {
  background-color: #ccecff6c;
}
</style>